// Lib
import * as Yup from "yup";
import { testValidPhone } from "../lib/testValidPhone";
// Schemas
import { WPTermSchema } from "./WPTerm";
import { VenueSchema } from "./VenueSchema";
export var AddYourVenueSchema = Yup.object().shape({
    user: Yup.object().shape({
        emailAddress: Yup.string().email('Please enter a valid email').required('Required'),
        name: Yup.string().required('Required'),
        telephoneNumber: Yup.string().test('valid-uk-phone', "Please enter a valid UK Phone Number", testValidPhone).required('Required')
    }),
    venue: Yup.object().shape(VenueSchema),
    venueType: Yup.array().min(1, 'Please select the Venue Type'),
    cuisineType: Yup.object().shape({
        primary: Yup.object(WPTermSchema).nullable().required('Please select a primary cuisine'),
        secondary: Yup.array(Yup.object(WPTermSchema).nullable())
    }),
    venueFeature: Yup.array().min(1, 'Please select at least one feature'),
    kitchenFreeFrom: Yup.array(),
    menuFreeFrom: Yup.array().min(1, 'Please select at least one allergen'),
    mealType: Yup.array().min(1, 'Please select at least one meal type'),
    acceptTerms: Yup.boolean().required().isTrue('You must accept the terms and conditions')
});
export var AddYourVenueTouched = {
    user: {
        emailAddress: true,
        name: true,
        telephoneNumber: true,
    },
    venue: {
        priceRating: true
    },
    venueType: true,
    cuisineType: {
        primary: true
    },
    venueFeature: true,
    kitchenFreeFrom: true,
    menuFreeFrom: true,
    mealType: true,
    acceptTerms: true
};
