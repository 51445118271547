import * as Yup from "yup";
import { testValidPostcode } from "../lib/testValidPostcode";
import { testValidPhone } from "../lib/testValidPhone";
export var VenueSchema = {
    id: Yup.string().nullable(),
    name: Yup.string().required('Required'),
    address: Yup.object().shape({
        addressLine1: Yup.string().required('Address Line 1 is required'),
        addressLine2: Yup.string(),
        addressLine3: Yup.string(),
        county: Yup.string().required('County is required'),
        postcode: Yup.string().required('Postcode is required').test('post-code', 'Please enter a valid UK Postcode', testValidPostcode),
        latLng: Yup.string(),
        placeID: Yup.string()
    }),
    image: Yup.mixed().required('Please upload an image'),
    website: Yup.string().nullable(),
    telephoneNumber: Yup.string().nullable().test('phone-number', 'Please enter a valid UK Phone number', testValidPhone),
    priceRating: Yup.number().required('Please select a price rating')
};
