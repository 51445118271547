var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// Lib
import React, { lazy, Suspense } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import API from "../lib/API";
import Modal from "react-modal";
// Components
import UserForm from "../components/UserForm";
import { checkboxesFallback } from "../components/fallbacks/checkboxesFallback";
var TaxonomySelection = lazy(function () { return import('../components/TaxonomySelection'); });
var CuisineSelection = lazy(function () { return import('../components/CuisineSelection'); });
var AddYourVenueSummary = lazy(function () { return import('../components/Summaries/AddYourVenueSummary'); });
var VenuePriceRating = lazy(function () { return import('../components/VenuePriceRating'); });
var Checkbox = lazy(function () { return import('../components/Checkbox'); });
// Helpers
import { getGroupClass } from "../lib/GroupClass";
import { renderErrorMessage } from "../lib/renderErrorMessage";
import { AddYourVenueSchema, AddYourVenueTouched } from "../schemas/AddYourVenue";
var AddYourVenue = /** @class */ (function (_super) {
    __extends(AddYourVenue, _super);
    function AddYourVenue() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            addressSelected: false,
            showForm: true,
            showSummary: false,
            showComplete: false,
            proceedCheck: false,
            manualAddressInput: false,
            venueAlreadyExists: false,
            venueExistsURL: ''
        };
        _this.defaultValues = {
            user: {
                emailAddress: '',
                name: '',
                telephoneNumber: ''
            },
            venue: {
                id: '',
                name: '',
                address: {
                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    county: '',
                    postcode: '',
                    latLng: '',
                    placeID: ''
                },
                website: '',
                telephoneNumber: '',
                image: '',
                priceRating: 0
            },
            venueType: [],
            cuisineType: {
                primary: {},
                secondary: []
            },
            venueFeature: [],
            kitchenFreeFrom: [],
            menuFreeFrom: [],
            mealType: [],
            acceptTerms: false
        };
        _this.saveVenue = function (values, actions) {
            actions.setSubmitting(true);
            API.saveVenue(values)
                .then(function () {
                actions.resetForm({
                    values: _this.defaultValues
                });
                _this.setState({ showSummary: false, showComplete: true }, _this.triggerNavChange);
                window.scrollTo(0, 0);
            })
                .catch(function (err) {
                console.error('[API.saveRestaurant] ' + err.message);
            })
                .finally(function () {
                actions.setSubmitting(false);
                if (typeof gtag !== 'undefined') {
                    gtag('event', 'page_view', {
                        page_title: document.title,
                        page_location: window.location.href + "thank-you",
                        page_path: window.location.pathname + "thank-you"
                    });
                    gtag('event', "add_a_venue_form_complete", {
                        'event_category': "add_a_venue_form",
                        'event_label': "Add A Venue Form"
                    });
                }
            });
        };
        return _this;
    }
    AddYourVenue.prototype.componentDidMount = function () {
        if (typeof gtag !== 'undefined') {
            gtag('event', "add_a_venue_form_start", {
                'event_category': "add_a_venue_form",
                'event_label': "Add A Venue"
            });
        }
        window.addEventListener('scroll', this.affixNav, { passive: true });
    };
    AddYourVenue.prototype.componentWillUnmount = function () {
        window.removeEventListener('scroll', this.affixNav);
    };
    AddYourVenue.prototype.render = function () {
        var _this = this;
        var taxonomySkeleton = checkboxesFallback();
        var checkboxSkeleton = React.createElement("span", { className: "checkbox-dummy" }, "Loading");
        return (React.createElement(Formik, { initialValues: this.defaultValues, validationSchema: AddYourVenueSchema, onReset: this.resetElements, onSubmit: this.saveVenue, validateOnMount: true }, function (props) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            var errors = props.errors;
            var touched = props.touched;
            var venueInput = _this.renderVenueInput(errors.venue, touched.venue, false);
            var formClass = _this.state.addressSelected ? "form venue-selected" : "form";
            return (React.createElement(Form, { className: formClass, onKeyDown: _this.onKeyDown, autoComplete: "off" },
                React.createElement("div", { id: "add-your-venue__form", style: { display: (_this.state.showForm) ? 'block' : 'none' } },
                    React.createElement("h1", { className: "page-title" }, "Add your venue for free"),
                    venueInput,
                    React.createElement("div", { id: "add-your-venue__other", className: "form__group", style: {
                            display: (_this.state.addressSelected || _this.state.manualAddressInput) ? 'block' : 'none'
                        } },
                        React.createElement("fieldset", { className: "form__fieldset", ref: _this.firstFieldset },
                            React.createElement("legend", null, "Venue information"),
                            React.createElement("div", { className: getGroupClass((_a = errors.venue) === null || _a === void 0 ? void 0 : _a.priceRating, !!((_b = touched.venue) === null || _b === void 0 ? void 0 : _b.priceRating)) },
                                React.createElement("label", { className: "form__label", htmlFor: "venuePrice" }, "Price *"),
                                React.createElement(Field, { id: "venuePrice", name: "venue.priceRating" }, function (props) { return (React.createElement(Suspense, { fallback: React.createElement("div", { className: "form__control" }) },
                                    React.createElement(VenuePriceRating, __assign({}, props)))); }),
                                React.createElement(ErrorMessage, { name: "venue.priceRating", render: renderErrorMessage }))),
                        React.createElement("fieldset", { className: "form__fieldset" },
                            React.createElement("div", { className: getGroupClass(errors.venueType, !!touched.venueType) },
                                React.createElement(Field, { name: "venueType" }, function (props) { return (React.createElement(Suspense, { fallback: taxonomySkeleton },
                                    React.createElement(TaxonomySelection, __assign({}, props, { taxonomySlug: "venue-type", introTitle: "Venue Type *", multiple: true })))); }),
                                React.createElement(ErrorMessage, { name: "venueType", render: renderErrorMessage }))),
                        React.createElement("fieldset", { className: "form__fieldset" },
                            React.createElement("div", { className: getGroupClass(errors.venueFeature, !!touched.venueFeature) },
                                React.createElement(Field, { name: "venueFeature" }, function (props) { return (React.createElement(Suspense, { fallback: taxonomySkeleton },
                                    React.createElement(TaxonomySelection, __assign({}, props, { taxonomySlug: "venue-feature", introTitle: "Features *", multiple: true })))); }),
                                React.createElement(ErrorMessage, { name: "venueFeature", render: renderErrorMessage }))),
                        React.createElement("fieldset", { className: "form__fieldset" },
                            React.createElement("div", { className: getGroupClass(errors.mealType, !!touched.mealType) },
                                React.createElement(Field, { name: "mealType" }, function (props) { return (React.createElement(Suspense, { fallback: taxonomySkeleton },
                                    React.createElement(TaxonomySelection, __assign({}, props, { taxonomySlug: "meal-type", introTitle: "Meal Types *", multiple: true })))); }),
                                React.createElement(ErrorMessage, { name: "mealType", render: renderErrorMessage }))),
                        React.createElement("fieldset", { className: "form__fieldset" },
                            React.createElement("div", { className: getGroupClass((_d = (_c = errors.cuisineType) === null || _c === void 0 ? void 0 : _c.primary) === null || _d === void 0 ? void 0 : _d.term_id, !!(touched === null || touched === void 0 ? void 0 : touched.cuisineType)) },
                                React.createElement(Field, { name: "cuisineType" }, function (props) { return (React.createElement(Suspense, { fallback: taxonomySkeleton },
                                    React.createElement(CuisineSelection, __assign({}, props, { taxonomySlug: "cuisine-type", multiple: true })))); }),
                                ((_f = (_e = errors.cuisineType) === null || _e === void 0 ? void 0 : _e.primary) === null || _f === void 0 ? void 0 : _f.term_id) && !!(touched === null || touched === void 0 ? void 0 : touched.cuisineType) && (React.createElement("span", { className: "form__error" }, "".concat((_h = (_g = errors.cuisineType) === null || _g === void 0 ? void 0 : _g.primary) === null || _h === void 0 ? void 0 : _h.term_id))))),
                        React.createElement("fieldset", { className: "form__fieldset" },
                            React.createElement("div", { className: getGroupClass(errors.menuFreeFrom, !!touched.menuFreeFrom) },
                                React.createElement(Field, { name: "menuFreeFrom" }, function (props) { return (React.createElement(Suspense, { fallback: taxonomySkeleton },
                                    React.createElement(TaxonomySelection, __assign({}, props, { taxonomySlug: "menu-free-from", introTitle: "Meals good for *", introContent: "Select allergens that your venue is able to cook meals without.", multiple: true })))); }),
                                React.createElement(ErrorMessage, { name: "menuFreeFrom", render: renderErrorMessage }))),
                        React.createElement("fieldset", { className: "form__fieldset" },
                            React.createElement("div", { className: getGroupClass(errors.kitchenFreeFrom, !!touched.kitchenFreeFrom) },
                                React.createElement(Field, { name: "kitchenFreeFrom" }, function (props) { return (React.createElement(Suspense, { fallback: taxonomySkeleton },
                                    React.createElement(TaxonomySelection, __assign({}, props, { taxonomySlug: "kitchen-free-from", introTitle: "Kitchen free-from", introContent: "Select allergens that are not used in your kitchen.", multiple: true })))); }),
                                React.createElement(ErrorMessage, { name: "kitchenFreeFrom", render: renderErrorMessage }))),
                        React.createElement("fieldset", { className: "form__fieldset" },
                            React.createElement("legend", null, "Your Information"),
                            React.createElement("div", { className: getGroupClass((_j = errors.user) === null || _j === void 0 ? void 0 : _j.name, (_k = touched.user) === null || _k === void 0 ? void 0 : _k.name) },
                                React.createElement("label", { htmlFor: "username", className: "form__label" }, "Contact Name *"),
                                React.createElement(Field, { id: "username", name: "user.name", className: "form__control", autocorrect: "off", placeholder: "Enter a contact name" }),
                                React.createElement(ErrorMessage, { name: "user.name", render: renderErrorMessage })),
                            React.createElement("div", { className: getGroupClass((_l = errors.user) === null || _l === void 0 ? void 0 : _l.telephoneNumber, (_m = touched.user) === null || _m === void 0 ? void 0 : _m.telephoneNumber) },
                                React.createElement("label", { htmlFor: "username", className: "form__label" }, "Contact telephone number *"),
                                React.createElement(Field, { id: "user-telephoneNumber", name: "user.telephoneNumber", className: "form__control", placeholder: "Enter a phone number" }),
                                React.createElement(ErrorMessage, { name: "user.telephoneNumber", render: renderErrorMessage })),
                            React.createElement("div", { className: getGroupClass((_o = errors.user) === null || _o === void 0 ? void 0 : _o.emailAddress, (_p = touched.user) === null || _p === void 0 ? void 0 : _p.emailAddress) },
                                React.createElement("label", { htmlFor: "emailAddress", className: "form__label" }, "Email Address *"),
                                React.createElement(Field, { id: "emailAddress", name: "user.emailAddress", className: "form__control", placeholder: "Enter an email address" }),
                                React.createElement(ErrorMessage, { name: "user.emailAddress", render: renderErrorMessage }))),
                        React.createElement("fieldset", { className: "form__fieldset" },
                            React.createElement("div", { className: "form__group" },
                                React.createElement(Field, { id: "show-name", name: "acceptTerms" }, function (props) { return (React.createElement(Suspense, { fallback: checkboxSkeleton },
                                    React.createElement(Checkbox, __assign({}, props, { label: "I agree to the <a href='/terms' target='_blank'>Terms and Conditions</a> and <a href='/acceptable-use-policy' target='_blank'>Acceptable Use Policy</a>", isNameCheckbox: false })))); }),
                                React.createElement(ErrorMessage, { name: "acceptTerms", render: renderErrorMessage })))),
                    React.createElement("div", { className: "form__actions", style: {
                            display: (_this.state.addressSelected || _this.state.manualAddressInput) ? 'block' : 'none'
                        } },
                        React.createElement("button", { type: "button", className: props.isValid ? "btn" : "btn btn--invalid", disabled: props.isSubmitting, onClick: function () { return _this.checkValid(props, AddYourVenueTouched); } }, "Proceed"))),
                React.createElement("div", { id: "add-your-venue__summary", style: { display: (_this.state.showSummary) ? 'block' : 'none' } },
                    React.createElement(Suspense, { fallback: React.createElement("div", null) },
                        React.createElement(AddYourVenueSummary, { onEdit: function () { return _this.setState({ showSummary: false, showForm: true }); }, values: props.values, venue: _this.state.venue })),
                    React.createElement("div", { className: "form__actions" },
                        React.createElement("button", { className: "btn", type: "submit", disabled: props.isSubmitting }, "Submit venue"))),
                React.createElement("div", { id: "add-your-venue__thanks", style: { display: (_this.state.showComplete) ? 'block' : 'none' } },
                    React.createElement("section", { className: "thankyou" },
                        React.createElement("img", { src: "/wp-content/themes/class/dist/images/thankyou.jpg", alt: "Thank you image", className: "thankyou__image" }),
                        React.createElement("h3", { className: "h1" }, "Thank you!"),
                        React.createElement("p", null, "Your venue has been submitted and will be reviewed for approval by a member of the AC team. We will notify you when it has been published."),
                        React.createElement("a", { href: "/allergy-aware-restaurant-register/", className: "btn", style: {
                                display: "block",
                                margin: "2rem auto 0",
                                maxWidth: "23rem"
                            } }, "Add another venue")),
                    React.createElement("section", { className: "standout" },
                        React.createElement("h3", { className: "h1" }, "Become a full member"),
                        React.createElement("p", null, "Full member benefits as follows:"),
                        React.createElement("ul", { className: "icon-list" },
                            React.createElement("li", { className: "icon-list__item" },
                                React.createElement("svg", { className: "icon-list__icon" },
                                    React.createElement("use", { xlinkHref: "#icon--socialmedia" })),
                                React.createElement("span", null, "Social media presence")),
                            React.createElement("li", { className: "icon-list__item" },
                                React.createElement("svg", { className: "icon-list__icon" },
                                    React.createElement("use", { xlinkHref: "#icon--loyalty" })),
                                React.createElement("span", null, "Customer loyalty")),
                            React.createElement("li", { className: "icon-list__item" },
                                React.createElement("svg", { className: "icon-list__icon" },
                                    React.createElement("use", { xlinkHref: "#icon--marketing" })),
                                React.createElement("span", null, "Marketing material")),
                            React.createElement("li", { className: "icon-list__item" },
                                React.createElement("svg", { className: "icon-list__icon" },
                                    React.createElement("use", { xlinkHref: "#icon--performance" })),
                                React.createElement("span", null, "Performance data"))),
                        React.createElement("a", { href: "/become-a-full-member", className: "btn" }, "Learn more"))),
                React.createElement(Modal, { id: "already-exists", isOpen: _this.state.venueAlreadyExists, contentLabel: "Venue Already Exists", style: {
                        content: {
                            maxWidth: "66rem",
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                        },
                    } },
                    React.createElement("div", { className: "modal__content" },
                        React.createElement("h3", { className: "ReactModal__title" }, "Selected venue already exists"),
                        React.createElement("div", { className: "form__group" },
                            React.createElement("p", null, "If you are the owner click below to get in touch with Allergy Companions"),
                            React.createElement("a", { href: "/contact-us", className: "btn btn--secondary" }, "Contact Us"),
                            React.createElement("button", { type: "button", className: "btn btn--outline", onClick: function () { return window.location.reload(); } }, "Go back"))))));
        }));
    };
    return AddYourVenue;
}(UserForm));
export default AddYourVenue;
