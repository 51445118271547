import * as Yup from "yup";
export var WPTermSchema = {
    term_id: Yup.number().required("Please select a primary cuisine type"),
    name: Yup.string(),
    slug: Yup.string(),
    term_group: Yup.number(),
    term_taxonomy_id: Yup.number(),
    taxonomy: Yup.string(),
    description: Yup.string(),
    parent: Yup.number(),
    count: Yup.number(),
    filter: Yup.string(),
    value: Yup.string(),
    label: Yup.string()
};
